import * as React from 'react';
import { PageProps, Link } from 'gatsby';
import Header from './header';
import Footer from './footer';
import Toggle from './toggle';

const Layout = ({ children }: PageProps) => {
  const [isDark, setIsDark] = React.useState<boolean>(false);
  return (
    <div className={isDark ? 'dark' : ''}>
      <div className="w-100 h-100 text-ct2 bg-cg9 dark:bg-cg9d">
        <div className="dark:bg-cg9d">
          <Header isDark={isDark} setIsDark={setIsDark} />
          <main>{children}</main>
          <hr className="mx-6" />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Layout;
