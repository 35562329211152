import 'typeface-montserrat';
import 'typeface-merriweather';
import './src/styles/global.css';
import * as React from 'react';
import Layout from './src/components/layout';

// const DarkModeContext = React.createContext('light');

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return (
    // <DarkModeContext.Provider value="light">
    //   <Layout {...props}>{element}</Layout>
    // </DarkModeContext.Provider>
    <Layout {...props}>{element}</Layout>
  );
};
