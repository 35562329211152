import React from 'react';
import { Switch } from '@headlessui/react';

interface ToggleProps {
  isDark: boolean;
  setIsDark: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string | null;
}

const Toggle = ({ className, isDark, setIsDark }: ToggleProps) => {
  return (
    <Switch
      checked={isDark}
      onChange={setIsDark}
      className={`${
        isDark ? 'bg-c2d' : 'bg-c1'
      } relative inline-flex items-center h-6 rounded-full w-11 ${className}`}
    >
      <span className="sr-only">Toggle Dark Mode</span>
      <span
        className={`${
          isDark ? 'translate-x-6 bg-cg1d' : 'translate-x-1 bg-cg9'
        } inline-block w-4 h-4 transform rounded-full`}
      />
    </Switch>
  );
};

export default Toggle;
