import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { DotsVerticalIcon, MenuIcon } from '@heroicons/react/outline';
import Toggle from './toggle';

const Header = (props: any) => {
  return (
    <header className="bg-cg9 ">
      {/* <header className="bg-cg9 sticky top-0 z-30 opacity-100"> */}
      <div className="container py-4 flex items-center justify-between text-ct1 dark:text-ct1d">
        <Link to="/" className="dark:grayscale">
          <StaticImage
            className=""
            imgClassName=""
            src="../images/logo.png"
            height={50}
            alt="Profile picture"
            loading="eager"
            placeholder="none"
          />
        </Link>
        <nav className="flex font-sans items-center justify-between p-3 pl-12 dark:text-ct1d">
          <div className="hidden sm:block space-x-4">
            <Link className="nav-item" to="/">
              Home
            </Link>
            <Link className="nav-item" to="#events">
              Events
            </Link>
            <Link className="nav-item" to="#news">
              News
            </Link>
            <Link className="nav-item" to="#subscribe">
              Subscribe
            </Link>
            <Link className="nav-item" to="#about">
              About
            </Link>
          </div>
          <Toggle className="mx-4 lg:mr-0" isDark={props.isDark} setIsDark={props.setIsDark} />
          <MenuIcon className="sm:hidden h-6 w-6 sm:h-7 sm:w-7 text-c1 dark:text-c1d"></MenuIcon>
        </nav>
      </div>
    </header>
  );
};

export default Header;
