import React from 'react';

const Footer = () => {
  return (
    <footer className="flex pt-4 pb-6 text-ct3 dark:text-ct3d bg-cg9 dark:bg-cg9d">
      <p className="mx-auto text-sm">
        Copyright © {new Date().getFullYear()},{` `}
        <a href="https://www.ericandrae.com">NOBS Rugby</a>
      </p>
    </footer>
  );
};

export default Footer;
